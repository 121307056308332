$(document).ready(function () {
    var container = $('#mainContainer');
    var dataAction = container.data('action');
    var element = document.createElement('script');
    element.src = 'https://www.mercadopago.com/v2/security.js';

    if (container && container.length > 0) {
        if (dataAction) {
            if (dataAction.includes('Search')) {
                element.setAttribute('view', 'search');
            } else if (dataAction.includes('Product')) {
                element.setAttribute('view', 'item');
            } else {
                element.setAttribute('view', '');
            }
        } else {
            element.setAttribute('view', 'home');
        }
    } else {
        element.setAttribute('view', '');
    }

    document.head.appendChild(element);
});
